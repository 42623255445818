import request from '@/utils/request';

export function uploadTinymceImage(blobInfo) {
  //   let formData = new FormData();
  //   formData.append('file', blobInfo.blob(), blobInfo.filename());
  //   return request({
  //     url: `tinymce/upload`,
  //     method: 'post',
  //     data: formData,
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     timeout: 0 // request timeout
  //   });
}

export function uploadImage(file) {
  let formData = new FormData();
  formData.append('file', file);
  return request({
    url: 'upload/image',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 0 // request timeout
  });
}

export default {
  uploadTinymceImage,
  uploadImage
};

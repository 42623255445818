<template>
  <!-- 匯款設定 -->
  <div class="main-content white">
    <div class="content-block">
      <div class="upload-pic-block passbook-img">
        <div class="group-img">
          <img :src="payCodeImg" alt="" />
          <div class="groupimg">
            <input
              type="file"
              accept="image/*"
              @change="previewFiles"
              multiple
            />
          </div>
        </div>
      </div>
      <div class="bottom-btn-block nofloat">
        <a class="btn-cta" href="#" @click="submit">
          確認上傳
        </a>
      </div>
    </div>

    <MessageAlert
      :show="showModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
import { getWechatPayCode, updateWechatPayCode } from '@/api/member';
import { uploadImage } from '@/api/upload';
import MessageAlert from '@/components/Modals/ModalMessageAlert';

export default {
  components: {
    MessageAlert
  },
  data() {
    return {
      payCodeImg: null,
      payCodeFile: null,
      isUploading: false,
      showModal: false,
      msgTitle: null,
      msgContent: null
    };
  },
  created() {
    getWechatPayCode().then(data => {
      this.payCodeImg = data.wechat_pay_code;
    });
  },
  mounted() {},
  methods: {
    previewFiles(e) {
      let files = e.target.files;
      if (files.length === 0) {
        return;
      }
      //一選擇圖片就顯示
      var reader = new FileReader();
      reader.onload = this.imageIsLoaded;
      reader.readAsDataURL(files[0]);

      //上傳之後再顯示
      this.uploadImage(files[0]);
    },
    imageIsLoaded(e) {
      $('.group-img img').attr('src', e.target.result);
      $('.group-img img').fadeIn();
    },
    uploadImage(file) {
      this.isUploading = true;

      uploadImage(file)
        .then(data => {
          this.payCodeImg = data.url;
          this.isUploading = false;
        })
        .catch(err => {
          this.isUploading = false;
        });
    },
    submit() {
      if (this.isUploading) {
        return;
      }

      updateWechatPayCode({
        wechat_pay_code: this.payCodeImg
      }).then(() => {
        this.showSuccessMsg();
      });
    },
    confirmMsg() {
      this.$router.push({ name: 'MemberCenterDefaultSetting' });
    },
    showSuccessMsg() {
      this.showModal = true;
      this.msgTitle = '提示';
      this.msgContent = '更新成功';
    }
  }
};
</script>
